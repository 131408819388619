import React, { useState } from "react";
import axios from "axios";
import Agricultor from "../assets/_beeland_agricultor.png"; // Import image asset
import Logo from "../assets/logo.png"; // Import image asset
import RegistrationMap from "./RegistrationMap"; // Import the map component for consistency
import "./Register.css"; // Import the custom CSS
import { URL } from "../../config";

const FarmerRegistration = ({ onUserRegister }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    lastname: "",
    nif: "",
    phone: "",
    address: "",
    postalCode: "",
    locality: "",
    farmArea: 0,
    cropType: "",
    lat: 0,
    long: 0,
    region: "",
    country: "",
    district: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddressChange = (value) => {
    setFormData({
      ...formData,
      address: value.street,
      postalCode: value.postalCode,
      locality: value.city,
      lat: value.lat,
      long: value.long,
      region: value.region,
      country: value.country,
      district: value.distrito,
    });
    console.log("Address changed to: ", value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Post data to the server
    axios
      .post(`${URL}/beeland-api/user/register/farmer`, formData)
      .then((response) => {
        console.log("Farmer registered successfully:", response.data);
      })
      .catch((error) => {
        console.error("There was an error registering the farmer!", error);
      });
  };

  return (
    <div className="content-layout">
      <img
        className="register-logo"
        style={{ cursor: "pointer" }}
        src={Logo}
        alt="logo"
        width={250}
        onClick={() => onUserRegister("")}
      />
      <div className="header-title">
        <span>
          <p style={{ textAlign: "left" }}>Bolsa de Polinização Apícola</p>
        </span>
      </div>
      <span className="register-title">Registo do Agricultor</span>
      Por favor, preencha o formulário de registo
      <div className="move-effect register-form-wrapper">
        <div className="move-effect-one left-column">
          <img src={Agricultor} alt="Agricultor" />
          <div className="title">AGRICULTOR</div>
          <div className="subtitle">(registo)</div>
          <div
            className="goback-register"
            style={{ marginTop: "2rem" }}
            onClick={() => onUserRegister("")}
          >
            Regressar
          </div>
        </div>
        <div className="move-effect right-column">
          <form onSubmit={handleSubmit}>
            <div className="input-group-left">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Nome"
                required
              />
              <input
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                placeholder="Apelido"
                required
              />
              <input
                type="text"
                name="nif"
                value={formData.nif}
                onChange={handleChange}
                placeholder="NIF"
                required
              />
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Telemóvel"
                required
              />
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Morada"
                required
              />
              <div className="form-subgroup item">
                <input
                  style={{ textAlign: "left" }}
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  placeholder="Cod. Postal"
                  required
                />
                <input
                  style={{
                    width: "90%",
                    textAlign: "left",
                    marginLeft: "1rem",
                  }}
                  type="text"
                  name="locality"
                  value={formData.locality}
                  onChange={handleChange}
                  placeholder="Localidade"
                  required
                />
              </div>
            </div>

            <div className="move-effect input-group-right">
              <div className="form-map-container">
                <RegistrationMap
                  range={formData.farmArea}
                  onUserInfoChange={handleAddressChange}
                />
              </div>
              <div className="form-subgroup">
                <div className="form-subgroup item">
                  <label>Área da cultura (Ha):</label>
                  <input
                    type="number"
                    name="farmArea"
                    min={1}
                    value={formData.farmArea}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-subgroup item">
                  <label>Tipo de cultura:</label>
                  <select
                    name="cropType"
                    value={formData.cropType}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected hidden>
                      Tipo de Cultura
                    </option>
                    <option value="fruit">Pomar</option>
                    <option value="vegetable">Morangal</option>
                    <option value="grain">Amendoal</option>
                    <option value="grain">Cerejal</option>
                  </select>
                </div>
              </div>
              <button type="submit">Registar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FarmerRegistration;
