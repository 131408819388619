import React, { useState } from "react";
import axios from "axios";
import Apicultor from "../assets/_beeland_apicultor.png";
import Logo from "../assets/logo.png";
import RegistrationMap from "./RegistrationMap";
import "./Register.css";
import { URL } from "../../config";

const BeekeeperRegistration = ({ onUserRegister }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    lastname: "",
    nif: "",
    phone: "",
    address: "",
    postalCode: "",
    locality: "",
    serviceArea: 50000,
    hiveCount: 0,
    serviceRate: 0,
    lat: 0,
    long: 0,
    region: "",
    country: "",
    district: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddressChange = (value) => {
    setFormData({
      ...formData,
      address: value.street,
      postalCode: value.postalCode,
      locality: value.city,
      lat: value.lat,
      long: value.long,
      region: value.region,
      country: value.country,
      district: value.distrito
    });
    console.log("Address changed to: ", value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Post data to the server
    axios
      .post(`${URL}/beeland-api/user/register/beekeeper`, formData)
      .then((response) => {
        console.log("Beekeeper registered successfully:", response.data);
      })
      .catch((error) => {
        console.error("There was an error registering the beekeeper!", error);
      });
  };

  return (
    <div className="content-layout">
      <img className="register-logo" style={{cursor:"pointer"}} src={Logo} alt="logo" width={250} onClick={() => onUserRegister("")} />
      <div className="header-title">
        <span>
          {" "}
          <p style={{ textAlign: "left" }}>Bolsa de Polinização Apícola</p>
        </span>
      </div>
      <span className="register-title">Registo do Apicultor</span>
      Por favor, preencha o formulário de registo
      <div className="move-effect register-form-wrapper">
        <div className="move-effect-one left-column">
          <img src={Apicultor} alt="Apicultor" />
          <div className="title">APICULTOR</div>
          <div className="subtitle">(registo)</div>
          <div className="goback-register" style={{marginTop:"2rem"}} onClick={() => onUserRegister("")}>
            Regressar
          </div>
        </div>
        <div className="right-column">
          <form onSubmit={handleSubmit}>
            <div className="move-effect-one input-group-left">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Nome"
                required
              />
              <input
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                placeholder="Apelido"
                required
              />
              <input
                type="text"
                name="nif"
                value={formData.nif}
                onChange={handleChange}
                placeholder="NIF"
                required
              />
               <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Telemóvel"
                required
              />
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Morada"
                required
              />
                <div className="form-subgroup item">

              <input
               style={{textAlign:"left"}}
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                placeholder="Cod. Postal"
                required
                />
              <input
              style={{width: "90%", textAlign:"left", marginLeft:"1rem"}}
                type="text"
                name="locality"
                value={formData.locality}
                onChange={handleChange}
                placeholder="Localidade"
                required
                />
                </div>
            </div>

            <div className="move-effect input-group-right">
              <div className="form-map-container"> 
              <RegistrationMap range={formData.serviceArea} onUserInfoChange={handleAddressChange} />
              </div>
              <div className="form-subgroup">
              <div className="form-subgroup item">

              <label>Distância do serviço a prestar:</label>
              <input
                type="range"
                name="serviceArea"
                //defaultValue={formData.serviceArea}
                min="10000"
                step={10000}
                max="150000"
                value={formData.serviceArea}
                onChange={handleChange}
                />
                <span>{parseInt(formData.serviceArea/1000)}Km</span>
              
                </div>
                <div className="form-subgroup item">
              <label>N.º de colmeias (uni):</label>
              <input
                type="number"
                name="hiveCount"
                min={1}
                value={formData.hiveCount}
                onChange={handleChange}
                />
                </div>
                <div className="form-subgroup item">
              <label>Valor mensal do serviço por colmeia solicitada (€):</label>
              <input
                type="number"
                name="serviceRate"
                min={0}
                value={formData.serviceRate}
                onChange={handleChange}
                />
                </div>
                </div>
              <button type="submit">Registar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BeekeeperRegistration;
