import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import Logo from "./assets/logo2.png"
import "./Login.css";
import { URL } from '../config';
import { useHistory } from "react-router-dom";
import { AuthContext } from './auth/AuthContext';

const Login = ({ onLogin, onRegister }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { loginUser } = useContext(AuthContext);

  const history = useHistory();

  localStorage.clear();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const url = `${URL}/beeland-api/authentication/login`;
      const response = await axios.post(url, { email, password });

      if (response.status === 200 && response.data.message) {
       
        loginUser(response.data.userData);
        onLogin(true);
       // history.push('/dashboard'); // Redirect to the dashboard or another appropriate route
      } else {
        setError('Invalid email or password');
      }
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || 'Invalid email or password');
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center login-page">
        <div className="header-title">
          <span>
            {" "}
            <p>Bolsa de Polinização Apícola</p>
          </span>
        </div>
        <div className="move-effect login-card-wrapper">
          <img
            src={Logo}
            alt="Logo"
            width={190}
            style={{ marginBottom: "1rem" }}
          />
          <div
            style={{
              background: "none",
              borderRadius: "0rem 0rem 0.9rem 0.9rem",
            }}
          >
            <Form className="login-form-wrapper" onSubmit={handleLogin}>
              <Form.Group
                className="login-form-item-wrapper"
                controlId="email"
              >
                <Form.Control
                  className="login-input-item-wrapper"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setError("");
                  }}
                  style={{ borderRadius: "0.5rem", fontSize: "small" }}
                  required
                />
              </Form.Group>
              <Form.Group
                className="login-form-item-wrapper"
                controlId="password"
              >
                <Form.Control
                  className="login-input-item-wrapper"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setError("");
                  }}
                  style={{ borderRadius: "0.5rem", fontSize: "small" }}
                  required
                />
              </Form.Group>
              <Form.Group
                style={{
                  height: "1.5rem",
                  display: "block",
                  marginTop: "1rem",
                }}
              >
                <div
                  className="error-wrapper"
                  style={{ display: error ? "" : "none" }}
                >
                  {error}
                </div>
              </Form.Group>
              <Form.Group className="login-form-item-wrapper">
                <button type="submit" className="login-button-btn">
                  Entrar
                </button>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="login-text-wrapper" style={{ marginTop: "3rem" }}>
          <span className="register-item" onClick={() => onRegister(true)}>
            Registar
          </span>
        </div>
        <div className="footer-developer">
          &copy;2024 -{" "}
          <span style={{ textDecoration: "none", color: "var(--text-color)" }}>
            developed by{" "}
          </span>
          <b>TECH</b> @{" "}
          <a
            href="http://www.morecolab.pt"
            style={{ textDecoration: "none", color: "var(--text-color)" }}
          >
            MORECOLAB.PT
          </a>
        </div>
      </div>
    </>
  );
};

export default Login;
